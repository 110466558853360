import React from 'react';
import { IBatteryChangeReminder } from '../../../pages/battery-change-reminder';
import { cannonStyled } from '../../../theme';
import Success from './Success';

const MainContainer = cannonStyled('div', {
  padding: 0,
  marginTop: '150px',
  width: '100%',
  height: '100%',
});

interface Props {
  data: IBatteryChangeReminder;
}

const BatteryChangeReminder = ({ data }: Props) => {
  // HOOKS

  // STATE

  // HANDLERS & FUNCTIONS

  // RENDER
  const {
    title = '',
    subtitle = '',
    description = '',
    successfulSubtitle,
    successfulTitle,
  } = data;
  return (
    <MainContainer>
      <Success successData={{ successfulSubtitle, successfulTitle }} />
    </MainContainer>
  );
};

export default BatteryChangeReminder;
