import React from 'react';
import {
  cannonStyled,
  NSH4,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import BatteryIcon from '../../../assets/battery-icon.svg';
import AnchorSuccess from '../../../atoms/Button/AnchorSuccess';

const MainContainer = cannonStyled('div', {
  marginTop: '14vh',
  marginBottom: '14vh',
});

interface Props {
  successData: { successfulTitle: string; successfulSubtitle: string };
}

const Success = ({ successData }: Props) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();

  //RENDER
  const { successfulTitle = '', successfulSubtitle = '' } = successData;
  return (
    <MainContainer>
      <BatteryIcon />
      <NSH4
        $style={{ fontSize: '36px', marginTop: '90px', paddingBottom: '30px' }}
        align='center'
        color={theme.cannonColors.primaryBlue}
        weight={900}
      >
        {successfulTitle}
      </NSH4>
      <TWParagraph3
        $style={{ fontSize: '20px', marginBottom: '50px' }}
        align='center'
        color={theme.cannonColors.secondarySilver}
        weight={400}
      >
        {successfulSubtitle}
      </TWParagraph3>

      <AnchorSuccess href={'/faq/?select=Changing%20Batteries'}>Battery change FAQ’s</AnchorSuccess>
    </MainContainer>
  );
};

export default Success;
